export function px2rem(pixel) {
  const { clientWidth } = document.body;
  return clientWidth > 1920 ? pixel : pixel * (clientWidth / 1920);
}

(function () {
  function documentElementStyle() {
    if (document.body.clientWidth > 1920) {
      document.documentElement.style.fontSize = "100px";
    } else {
      const WW = document.body.clientWidth; // 当前窗口的宽度
      const WH_DEF = 100 / 1920; // 表示1920的设计图,使用100PX的默认值
      document.documentElement.style.fontSize = WW * WH_DEF + "px";
    }
  }

  // 当初始的 HTML 文档被完全加载和解析完成之后，DOMContentLoaded 事件被触发，而无需等待样式表、图像和子框架的完成加载
  document.addEventListener(
    "DOMContentLoaded",
    function () {
      documentElementStyle();
    },
    false
  );

  // onresize 事件会在窗口或框架被调整大小时发生。
  // 苹果公司为移动 Safari中添加了 orientationchange 事件，以便开发人员能够确定用户何时将设 备由横向查看模式切换为纵向查看模式
  const resizeEvt =
    "orientationchange" in window ? "orientationchange" : "resize";
  window.addEventListener(
    resizeEvt,
    function () {
      documentElementStyle();
    },
    false
  );
})();
