import { createApp } from "vue";
import App from "./App.vue";
// 路由配置
import router from "./router";
// 导入ant
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
import "animate.css";

import "tailwindcss/tailwind.css";

import "./styles/index.scss";

// rem 自适应
import "./common/plugins/rem";

const app = createApp(App);
app.use(Antd);
app.use(router);

// app.config.productionTip = false

app.mount("#app");
